/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuditLog {
	affectedColumns?: string | null;
	auditAction?: string | null;
	/** @format date-time */
	auditDate: string;
	auditUser?: string | null;
	/** @format date-time */
	creationTime: string;
	databaseName?: string | null;
	/** @format uuid */
	id: string;
	keyValues?: string | null;
	newValues?: string | null;
	oldValues?: string | null;
	tableName?: string | null;
}

export type AuditV1BulkCreatePayload = CreateAuditLogRequest[];

export type AuditV1HistoryBulkCreatePayload = HistoryRequest[];

export interface AuditV1SearchHistoryListParams {
	KeyValues?: string;
	Type?: TableNameType;
}

export interface AuditV1SearchListParams {
	KeyValues?: string;
	Type?: TableNameType;
}

export interface CreateAuditLogRequest {
	affectedColumns?: string | null;
	auditAction: string;
	/** @format date-time */
	auditDate: string;
	auditUser: string;
	databaseName?: string | null;
	keyValues: string;
	newValues?: string | null;
	oldValues?: string | null;
	tableName: string;
}

export interface GetVersionResponse {
	version?: string | null;
}

export type HistoryOperation = "none" | "created" | "updated" | "deleted";

export interface HistoryRecord {
	currentValue?: string | null;
	previousValue?: string | null;
	property: string;
}

export interface HistoryRequest {
	entityId: string;
	entityName: string;
	operation: HistoryOperation;
	records: HistoryRecord[];
	/** @format date-time */
	timestamp: string;
	user?: string | null;
}

export interface HistoryResponse {
	/** @format date-time */
	date: string;
	/** @format uuid */
	id: string;
	operation: HistoryOperation;
	records: HistoryRecord[];
	user?: string | null;
}

export interface StatusCode {
	code?: string | null;
	message: string;
}

export interface StringStringFAnonymousType0 {
	message?: string | null;
	status?: string | null;
}

export type TableNameType =
	| "none"
	| "attachment"
	| "customer"
	| "goods"
	| "job"
	| "leg"
	| "load";

export namespace Audit {
	/**
	 * No description
	 * @tags Audit
	 * @name AuditV1BulkCreate
	 * @summary Saves bulk audit logs
	 * @request POST:/audit-gateway/audit/v1/bulk
	 * @secure
	 * @response `201` `void` Created
	 * @response `400` `StatusCode` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace AuditV1BulkCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = AuditV1BulkCreatePayload;
		export type RequestHeaders = {};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Audit
	 * @name AuditV1Create
	 * @summary Saves audit log
	 * @request POST:/audit-gateway/audit/v1
	 * @secure
	 * @response `201` `void` Created
	 * @response `400` `StatusCode` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace AuditV1Create {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateAuditLogRequest;
		export type RequestHeaders = {};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Audit
	 * @name AuditV1HistoryBulkCreate
	 * @summary Saves bulk history logs
	 * @request POST:/audit-gateway/audit/v1/history/bulk
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `StatusCode` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace AuditV1HistoryBulkCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = AuditV1HistoryBulkCreatePayload;
		export type RequestHeaders = {};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Audit
	 * @name AuditV1HistoryCreate
	 * @summary Saves history log
	 * @request POST:/audit-gateway/audit/v1/history
	 * @secure
	 * @response `201` `void` Created
	 * @response `400` `StatusCode` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace AuditV1HistoryCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = HistoryRequest;
		export type RequestHeaders = {};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Audit
	 * @name AuditV1SearchHistoryList
	 * @summary Search for history logs
	 * @request GET:/audit-gateway/audit/v1/search-history
	 * @secure
	 * @response `200` `(HistoryResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace AuditV1SearchHistoryList {
		export type RequestParams = {};
		export type RequestQuery = {
			KeyValues?: string;
			Type?: TableNameType;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = HistoryResponse[];
	}

	/**
	 * No description
	 * @tags Audit
	 * @name AuditV1SearchList
	 * @summary Search for audit logs
	 * @request GET:/audit-gateway/audit/v1/search
	 * @secure
	 * @response `200` `(AuditLog)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace AuditV1SearchList {
		export type RequestParams = {};
		export type RequestQuery = {
			KeyValues?: string;
			Type?: TableNameType;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = AuditLog[];
	}
}

export namespace Version {
	/**
	 * No description
	 * @tags Version
	 * @name VersionList
	 * @summary Get version number of the API.
	 * @request GET:/audit-gateway/version
	 * @secure
	 * @response `200` `GetVersionResponse` OK
	 * @response `500` `string` Internal Server Error
	 */
	export namespace VersionList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = GetVersionResponse;
	}
}

import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<
	FullRequestParams,
	"body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | undefined> | AxiosRequestConfig | undefined;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || "",
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig,
	): AxiosRequestConfig {
		const method = params1.method || params2?.method;

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[
						method.toLowerCase() as keyof HeadersDefaults
					]) ||
					{}),
				...(params1.headers || {}),
				...(params2?.headers || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === "object" && formItem !== null) {
			return JSON.stringify(formItem);
		}
		return `${formItem}`;
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input;
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = Array.isArray(property)
				? property
				: [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(
					key,
					isFileType ? formItem : this.stringifyFormItem(formItem),
				);
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (
			type === ContentType.FormData &&
			body &&
			body !== null &&
			typeof body === "object"
		) {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (
			type === ContentType.Text &&
			body &&
			body !== null &&
			typeof body !== "string"
		) {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type ? { "Content-Type": type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @title Celerum TLS Audit.API
 * @version v1
 */
export class AuditApi<SecurityDataType> extends HttpClient<SecurityDataType> {
	audit = {
		/**
		 * No description
		 *
		 * @tags Audit
		 * @name AuditV1BulkCreate
		 * @summary Saves bulk audit logs
		 * @request POST:/audit-gateway/audit/v1/bulk
		 * @secure
		 * @response `201` `void` Created
		 * @response `400` `StatusCode` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		auditV1BulkCreate: (
			data: AuditV1BulkCreatePayload,
			params: RequestParams = {},
		) =>
			this.request<void, StatusCode | StringStringFAnonymousType0>({
				path: "/audit-gateway/audit/v1/bulk",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Audit
		 * @name AuditV1Create
		 * @summary Saves audit log
		 * @request POST:/audit-gateway/audit/v1
		 * @secure
		 * @response `201` `void` Created
		 * @response `400` `StatusCode` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		auditV1Create: (data: CreateAuditLogRequest, params: RequestParams = {}) =>
			this.request<void, StatusCode | StringStringFAnonymousType0>({
				path: "/audit-gateway/audit/v1",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Audit
		 * @name AuditV1HistoryBulkCreate
		 * @summary Saves bulk history logs
		 * @request POST:/audit-gateway/audit/v1/history/bulk
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `StatusCode` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		auditV1HistoryBulkCreate: (
			data: AuditV1HistoryBulkCreatePayload,
			params: RequestParams = {},
		) =>
			this.request<void, StatusCode | StringStringFAnonymousType0>({
				path: "/audit-gateway/audit/v1/history/bulk",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Audit
		 * @name AuditV1HistoryCreate
		 * @summary Saves history log
		 * @request POST:/audit-gateway/audit/v1/history
		 * @secure
		 * @response `201` `void` Created
		 * @response `400` `StatusCode` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		auditV1HistoryCreate: (data: HistoryRequest, params: RequestParams = {}) =>
			this.request<void, StatusCode | StringStringFAnonymousType0>({
				path: "/audit-gateway/audit/v1/history",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Audit
		 * @name AuditV1SearchHistoryList
		 * @summary Search for history logs
		 * @request GET:/audit-gateway/audit/v1/search-history
		 * @secure
		 * @response `200` `(HistoryResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		auditV1SearchHistoryList: (
			query: AuditV1SearchHistoryListParams,
			params: RequestParams = {},
		) =>
			this.request<HistoryResponse[], StringStringFAnonymousType0>({
				path: "/audit-gateway/audit/v1/search-history",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Audit
		 * @name AuditV1SearchList
		 * @summary Search for audit logs
		 * @request GET:/audit-gateway/audit/v1/search
		 * @secure
		 * @response `200` `(AuditLog)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		auditV1SearchList: (
			query: AuditV1SearchListParams,
			params: RequestParams = {},
		) =>
			this.request<AuditLog[], StringStringFAnonymousType0>({
				path: "/audit-gateway/audit/v1/search",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),
	};
	version = {
		/**
		 * No description
		 *
		 * @tags Version
		 * @name VersionList
		 * @summary Get version number of the API.
		 * @request GET:/audit-gateway/version
		 * @secure
		 * @response `200` `GetVersionResponse` OK
		 * @response `500` `string` Internal Server Error
		 */
		versionList: (params: RequestParams = {}) =>
			this.request<GetVersionResponse, string>({
				path: "/audit-gateway/version",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
}
