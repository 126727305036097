import type { CSSProperties, ReactNode } from "react";

type FlexProps = {
	children: ReactNode;
	gap?: string;
	noGap?: boolean;
	column?: boolean;
	spaceBetween?: boolean;
	flexEnd?: boolean;
	center?: boolean;
	fullHeight?: boolean;
	style?: CSSProperties;
	margin?: string;
	wrap?: boolean;
	stopPropagation?: boolean;
};
export const DFlex = (props: FlexProps) => (
	<div
		style={{
			display: "flex",
			gap: props.noGap ? undefined : (props.gap ?? "16px"),
			flexDirection: props.column ? "column" : "row",
			flexWrap: props.wrap ? "wrap" : undefined,
			justifyContent: props.spaceBetween
				? "space-between"
				: props.flexEnd
					? "flex-end"
					: undefined,
			alignItems: props.center ? "center" : undefined,
			height: props.fullHeight ? "100%" : undefined,
			margin: props.margin,
			...props.style,
		}}
		onClick={props.stopPropagation ? (e) => e.stopPropagation() : undefined}
		onKeyUp={props.stopPropagation ? (e) => e.stopPropagation() : undefined}
	>
		{props.children}
	</div>
);
