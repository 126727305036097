/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateInvoiceRequest {
	accountCode: string;
	businessUnitId: string;
	/** @format date-time */
	date: string;
	details: InvoiceDetailResponse[];
	isEuCustomer: boolean;
}

export interface CustomerResponse {
	accountCode: string;
	addressLines: string[];
	currencyCode?: string | null;
	emails: string[];
	isActive: boolean;
	isEuCustomer: boolean;
	name: string;
	phones: string[];
	vatNumber?: string | null;
}

export interface GetVersionResponse {
	version?: string | null;
}

export interface InvoiceDetailResponse {
	goodsDetails?: string | null;
	jobTypeName?: string | null;
	jobTypeNominalCode?: string | null;
	/** @format double */
	price: number;
	/** @format double */
	quantity: number;
	uniqueId?: string | null;
	/** @format double */
	vatRate: number;
}

export interface InvoiceResponse {
	/** @format date-time */
	invoiceDate?: string | null;
	invoiceNumber?: string | null;
}

export interface StatusCode {
	code?: string | null;
	message: string;
}

export interface StringStringFAnonymousType0 {
	message?: string | null;
	status?: string | null;
}

export namespace Sage {
	/**
	 * No description
	 * @tags Sage
	 * @name SageV1CustomersDetail
	 * @summary Gets customers from SAGE
	 * @request GET:/sage-gateway/sage/v1/customers/{businessUnitId}
	 * @secure
	 * @response `200` `(CustomerResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace SageV1CustomersDetail {
		export type RequestParams = {
			businessUnitId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CustomerResponse[];
	}

	/**
	 * No description
	 * @tags Sage
	 * @name SageV1InvoiceCreate
	 * @summary Creates an invoice in SAGE
	 * @request POST:/sage-gateway/sage/v1/invoice
	 * @secure
	 * @response `200` `InvoiceResponse` OK
	 * @response `400` `StatusCode` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace SageV1InvoiceCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateInvoiceRequest;
		export type RequestHeaders = {};
		export type ResponseBody = InvoiceResponse;
	}
}

export namespace Version {
	/**
	 * No description
	 * @tags Version
	 * @name VersionList
	 * @summary Get version number of the API.
	 * @request GET:/sage-gateway/version
	 * @secure
	 * @response `200` `GetVersionResponse` OK
	 * @response `500` `string` Internal Server Error
	 */
	export namespace VersionList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = GetVersionResponse;
	}
}

import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<
	FullRequestParams,
	"body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | undefined> | AxiosRequestConfig | undefined;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || "",
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig,
	): AxiosRequestConfig {
		const method = params1.method || params2?.method;

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[
						method.toLowerCase() as keyof HeadersDefaults
					]) ||
					{}),
				...(params1.headers || {}),
				...(params2?.headers || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === "object" && formItem !== null) {
			return JSON.stringify(formItem);
		}
		return `${formItem}`;
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input;
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = Array.isArray(property)
				? property
				: [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(
					key,
					isFileType ? formItem : this.stringifyFormItem(formItem),
				);
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (
			type === ContentType.FormData &&
			body &&
			body !== null &&
			typeof body === "object"
		) {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (
			type === ContentType.Text &&
			body &&
			body !== null &&
			typeof body !== "string"
		) {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type ? { "Content-Type": type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @title Celerum TLS Sage.API
 * @version v1
 */
export class SageApi<SecurityDataType> extends HttpClient<SecurityDataType> {
	sage = {
		/**
		 * No description
		 *
		 * @tags Sage
		 * @name SageV1CustomersDetail
		 * @summary Gets customers from SAGE
		 * @request GET:/sage-gateway/sage/v1/customers/{businessUnitId}
		 * @secure
		 * @response `200` `(CustomerResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		sageV1CustomersDetail: (
			businessUnitId: string,
			params: RequestParams = {},
		) =>
			this.request<CustomerResponse[], StringStringFAnonymousType0>({
				path: `/sage-gateway/sage/v1/customers/${businessUnitId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Sage
		 * @name SageV1InvoiceCreate
		 * @summary Creates an invoice in SAGE
		 * @request POST:/sage-gateway/sage/v1/invoice
		 * @secure
		 * @response `200` `InvoiceResponse` OK
		 * @response `400` `StatusCode` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		sageV1InvoiceCreate: (
			data: CreateInvoiceRequest,
			params: RequestParams = {},
		) =>
			this.request<InvoiceResponse, StatusCode | StringStringFAnonymousType0>({
				path: "/sage-gateway/sage/v1/invoice",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	version = {
		/**
		 * No description
		 *
		 * @tags Version
		 * @name VersionList
		 * @summary Get version number of the API.
		 * @request GET:/sage-gateway/version
		 * @secure
		 * @response `200` `GetVersionResponse` OK
		 * @response `500` `string` Internal Server Error
		 */
		versionList: (params: RequestParams = {}) =>
			this.request<GetVersionResponse, string>({
				path: "/sage-gateway/version",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
}
