import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { authStoreLogin, authStoreSelectBusinessUnit } from "../new/authStore";
import { LoginForm } from "../new/forms/LoginForm";
import { DriverLayout } from "./DriverLayout";

export const LoginPage = () => {
	useTitle("Login");
	const navigate = useNavigate();
	return (
		<DriverLayout>
			<LoginForm
				onSubmit={async (x) => {
					try {
						const res = await authStoreLogin(x);
						const buId = res.data.businessUnits[0]?.id;
						if (!buId) throw new Error("No business unit");
						await authStoreSelectBusinessUnit(buId);
						navigate("/");
					} catch (error) {
						alert(JSON.stringify(error));
					}
				}}
			/>
		</DriverLayout>
	);
};
